<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import branchComponent from "@/components/branchComponent.vue";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: appConfig.loanApplication,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: appConfig.loanApplication,
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },
        {
          text: appConfig.loanApplication,
          active: false,
        },
      ],
      optionFinance: [],
      rowsSelect: [],
      loadingCreate: false,
      loading: false,
      chk: "",
      selected: "",
      rowsTotalpages: "",
      totalPage: "",
      totalRecord: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPageCreate: 1,
      perPageCreate: 10,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      branchMaster: [],
      rowsRo: [],
      rowsCreate: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      payment: "",
      branch: "",
      type: "",
      saleId: "",
      loanId: "",
      serviceRo: [],
      selectMode: "single",
      userBranchId: "",
      // receipt: [
      //   {
      //     id: 1,
      //     receiptName: "ออกใบกำกับภาษีปกติ",
      //   },
      //   {
      //     id: 2,
      //     receiptName: "ออกใบกำกับภาษีเคลม Warranty",
      //   },
      // ],
      pay: [
        {
          id: 1,
          payName: "ชำระเงินสด",
        },
        {
          id: 2,
          payName: "เครดิต",
        },
      ],
      filter: {
        bookingCode: "",
        customer: "",
        loanCode: "",
        startDate: "",
        endDate: "",
        status: "",
        userBranchId: "",
        teamId: "",
        userId: "",
        finance: "",
      },
      filterCreate: {
        branch: "",
        saleCode: "",
        bookingCode: "",
        customer: "",
      },
      optionUser: [],
      optionTeam: [],
      optionBranch: [],
      userBranch: "",
      urlPrint: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      receipt: [
        {
          id: 1,
          receiptName: "ออกใบแจ้งหนี้ปกติ",
        },
        {
          id: 2,
          receiptName: "ออกใบแจ้งหนี้เคลม Warranty",
        },
      ],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        // {
        //   key: "bookingCode",
        //   sortable: true,
        //   label: "เลขที่ใบจอง",
        // },
        {
          key: "loanCode",
          sortable: true,
          label: "เลขที่ไฟแนนซ์",
        },
        {
          key: "financialNameTh",
          sortable: true,
          label: "ชื่อไฟแนนซ์",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "สกุล",
        },
        // {
        //   key: "licensePlate",
        //   sortable: true,
        //   label: "ป้ายทะเบียน",
        // },
        {
          key: "loanDate",
          sortable: true,
          label: "วันที่",
        },
        {
          key: "productNameEn",
          sortable: true,
          label: "ชื่อสินค้า",
          // class: "text-end",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          sortable: false,
          label: appConfig.action,
        },
      ],
      fieldsCreate: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "saleCode",
          sortable: true,
          label: "รหัสขายสินค้า",
        },
        {
          key: "bookingCode",
          sortable: true,
          label: "รหัสใบจองสินค้า",
        },
        {
          key: "productNameTh",
          sortable: true,
          label: "ชื่อสินค้า",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "สกุล",
        },
        {
          key: "vin",
          sortable: true,
          label: "เลขตัวถัง",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
          class: "text-end",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
    this.getTeam();
    this.getUser();
    this.getFinance();
    // this.getDataCreate();
  },
  created() {
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getFinance: function (fin) {
      // this.overlayFlag = true;
      useNetw
        .get("api/loan-application/financial-institution", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.filter.userBranchId.branchId != null
                ? this.filter.userBranchId.branchId
                : this.userBranchId,
            nameTh: fin,
            // familyName: this.filter.inquireCode,
          },
        })
        .then((response) => {
          this.optionFinance = response.data.data;
          this.optionFinance.to = response.data.to;
          this.optionFinance.from = response.data.from;
          this.optionFinance.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            // appConfig.swal.title.Unauthorization,
            appConfig.swal.type.error
            // appConfig.swal.title.Unauthorization
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getUser: function (name) {
      // this.overlayFlag = true;
      useNetw
        .get("api/loan-application/dealer-user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.filter.userBranchId != null
                ? this.filter.userBranchId.branchId
                : undefined,
            name: name,
            // familyName: name,
          },
        })
        .then((response) => {
          this.optionUser = response.data.data;
          this.optionUser.to = response.data.to;
          this.optionUser.from = response.data.from;
          this.optionUser.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            // appConfig.swal.title.Unauthorization,
            appConfig.swal.type.error
            // appConfig.swal.title.Unauthorization
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getTeam: function (team) {
      // this.overlayFlag = true;
      useNetw
        .get("api/loan-application/dealer-team", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.filter.userBranchId != null
                ? this.filter.userBranchId.branchId
                : undefined,
            name: team,
            // familyName: this.filter.inquireCode,
          },
        })
        .then((response) => {
          this.optionTeam = response.data.data;
          this.optionTeam.to = response.data.to;
          this.optionTeam.from = response.data.from;
          this.optionTeam.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            // appConfig.swal.title.Unauthorization,
            appConfig.swal.type.error
            // appConfig.swal.title.Unauthorization
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getData: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/loan-application", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.filter.userBranchId.branchId != null
                ? this.filter.userBranchId.branchId
                : this.userBranchId,
            status: this.filter.status,
            bookingCode: this.filter.bookingCode,
            loanCode: this.filter.loanCode,
            customer: this.filter.customer,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            teamId:
              this.filter.teamId != null
                ? this.filter.teamId.teamId
                : undefined,
            userId:
              this.filter.userId != null
                ? this.filter.userId.userId
                : undefined,
            fiId:
              this.filter.finance != null
                ? this.filter.finance.fiId
                : undefined,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    customLabel({ nameTh, familyName }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyName != null ? familyName : " "
      }`;
    },
    getDataCreate: function () {
      this.loadingCreate = true;
      useNetw
        .get("api/payment/branch-vehicle-sale", {
          params: {
            page: this.currentPageCreate,
            perPage: this.perPageCreate,
            branchId:
              this.filterCreate.branch != null
                ? this.filterCreate.branch.branchId
                : this.userBranchId,
            saleCode: this.filterCreate.saleCode,
            bookingCode: this.filterCreate.bookingCode,
            customer: this.filterCreate.customer,
          },
        })
        .then((response) => {
          this.rowsCreate = response.data.data;
          this.rowsCreate.total = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loadingCreate = false;
          this.isHidden = true;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    handleChangePageCreate(page) {
      this.currentPageCreate = page;
      this.getDataCreate();
    },
    handlePageChangeCreate(active) {
      this.perPageCreate = active;
      this.currentPageCreate = 1;
      this.getDataCreate();
    },
    onRowSelected(rows) {
      this.selected = rows[0];
      // this.alertConfirm(this.selected.saleId);
      // this.resetModal();
      // this.showRo();
    },
    deleteLoan(loanId) {
      this.loanId = atob(loanId);
      this.overlayFlag = true;
      useNetw
        .delete("api/loan-application/delete", {
          data: {
            loanId: this.loanId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          // this.getDataCreate();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getData();
    },
    handleSearchCreate() {
      this.getDataCreate();
    },
    // showRo() {
    //   this.getDataRo();
    // },
    alertDelete(loanId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบคำขออนุมัติไฟแนนซ์หรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteLoan(loanId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    getPrint(loanId) {
      this.loanId = loanId;
      this.loading = true;
      useNetw
        .get("api/loan-application/printLoan", {
          params: {
            loanId: this.loanId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = this.urlPrint;
          // commit;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getPrintChecklist(loanId) {
      this.loanId = loanId;
      this.loading = true;
      useNetw
        .get("api/loan-application/print-check-Loan", {
          params: {
            loanId: this.loanId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = this.urlPrint;
          // commit;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ออกใบกำกับภาษี:
                        <multiselect
                          v-model="type"
                          label="receiptName"
                          type="search"
                          :options="receipt"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        สาขา:
                        <multiselect
                          v-model="filter.userBranchId"
                          label="nameTh"
                          type="search"
                          :options="optionBranch"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่ใบจอง:
                        <b-form-input
                          v-model="filter.bookingCode"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่ไฟแนนซ์:
                        <b-form-input
                          v-model="filter.loanCode"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ชื่อลูกค้า:
                        <b-form-input
                          v-model="filter.customer"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        วันที่เริ่มต้น:
                        <date-picker
                          v-model="filter.startDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        วันที่สิ้นสุด:
                        <date-picker
                          v-model="filter.endDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        ทีม:
                        <multiselect
                          v-model="filter.teamId"
                          label="name"
                          type="search"
                          :options="optionTeam"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @search-change="getTeam($event)"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        ที่ปรึกษาการขาย:
                        <multiselect
                          v-model="filter.userId"
                          label="nameTh"
                          type="search"
                          :options="optionUser"
                          :custom-label="customLabel"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @search-change="getUser($event)"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        สถานบันการเงิน:
                        <multiselect
                          v-model="filter.finance"
                          label="nameTh"
                          type="search"
                          :options="optionFinance"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @search-change="getFinance($event)"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end m-1"
                    variant="info"
                    type="submit"
                    @click="handleSearch"
                  >
                    <i class="uil-search-alt"></i>
                    ค้นหา
                  </b-button>
                  <router-link
                    class="btn btn-primary float-end m-1"
                    :to="{
                      name: 'create-loan',
                    }"
                  >
                    <i class="uil-plus"></i>
                    สร้าง
                  </router-link>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ
                    </label>
                  </div>
                </div>
              </div>
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <div class="table-responsive mb-0">
                  <b-table
                    :items="rows"
                    :fields="fields"
                    :current-page="1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                  >
                    <template #cell(index)="rows">
                      {{ rows.index + 1 }}
                    </template>
                    <template #cell(status)="rowRo">
                      <span
                        class="badge bg-warning font-size-12"
                        v-if="rowRo.item.status === 'DFT'"
                      >
                        ร่าง
                      </span>
                      <span
                        class="badge bg-info font-size-12"
                        v-if="rowRo.item.status === 'SUBMIT'"
                      >
                        รออนุมัติ
                      </span>
                      <span
                        class="badge bg-success font-size-12"
                        v-if="rowRo.item.status === 'APV'"
                      >
                        อนุมัติ
                      </span>
                      <span
                        class="badge bg-danger font-size-12"
                        v-if="rowRo.item.status === 'NAPV'"
                      >
                        ไม่อนุมัติ
                      </span>
                      <span
                        class="badge bg-danger font-size-12"
                        v-if="rowRo.item.status === 'CAN'"
                      >
                        ยกเลิก
                      </span>
                    </template>
                    <template v-slot:cell(action)="rowsRo">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <router-link
                            href="javascript:void(0);"
                            class="px-2 text-primary"
                            v-b-tooltip.hover
                            :to="{
                              name: 'detail-loan',
                              params: { loanId: rowsRo.item.loanIdBase64 },
                            }"
                            title="View"
                          >
                            <i class="uil uil-pen font-size-18"></i>
                          </router-link>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                            @click="alertDelete(rowsRo.item.loanIdBase64)"
                          >
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-success"
                            v-b-tooltip.hover
                            title="Print"
                            @click="getPrint(rowsRo.item.loanId)"
                          >
                            <i class="uil-print font-size-18"></i>
                          </a>
                        </li>

                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-info"
                            v-b-tooltip.hover
                            title="Checklist"
                            @click="getPrintChecklist(rowsRo.item.loanId)"
                          >
                            <i
                              class="mdi mdi-format-list-checks font-size-18"
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </template>
                  </b-table>
                </div>
              </b-skeleton-wrapper>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <!-- modallllllllllllllllllllllllllllllllllllllllll -->
    <b-modal
      ref="modalCreateTaxInvoice"
      id="modalCreateTaxInvoice"
      title="ค้นหารายการชำระเงิน"
      hide-footer
      centered
      size="xl"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  สาขา:
                  <multiselect
                    v-model="filterCreate.branch"
                    label="nameTh"
                    type="search"
                    :options="optionBranch"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    @input="handleSearchCreate"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  รหัสขายสินค้า:
                  <b-form-input
                    v-model="filterCreate.saleCode"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  รหัสใบจองสินค้า:
                  <b-form-input
                    v-model="filterCreate.bookingCode"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filterCreate.customer"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <!-- <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขที่ใบกำกับภาษี:
                  <b-form-input
                    v-model="filterCreate.licensePlate"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
            <b-button
              class="btn float-end"
              variant="info"
              type="submit"
              @click="handleSearchCreate"
            >
              <i class="uil-search-alt"></i>
              ค้นหา
            </b-button>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loadingCreate">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChangeCreate"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table
              :items="rowsCreate"
              :fields="fieldsCreate"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <template #cell(index)="rowsCreate">
                {{ rowsCreate.index + 1 }}
              </template>
            </b-table>
          </div>
          <!-- <div class="text-center">
            <h6><b>ไม่พบข้อมูล</b></h6>
          </div> -->

          <div class="row">
            <div class="col">
              หน้า {{ currentPageCreate }} จาก
              {{ this.rowsCreate.total }} ทั้งหมด {{ this.totalRecord }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPageCreate"
                    :total-rows="totalRecord"
                    :per-page="perPageCreate"
                    @change="handleChangePageCreate"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
        </b-skeleton-wrapper>
      </div>
    </b-modal>
  </Layout>
</template>
